import React from 'react';
import NavItem from './NavItem';

const NavItems = ({ items, lang, mobile, close }) => {
  if (!items ) {
    // Handle the case when items or items.items is undefined
    return null; // or render an alternative UI
  }

  return (
    <>
      {items.map((item) => (
        <NavItem item={item} lang={lang} key={item['@id']} depth={1} mobile={mobile} close={close}/>
      ))}
    </>
  );
};

export default NavItems;
