/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import { Container, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Navigation from '../Navigation/Navigation.jsx'
import {
  Anontools,
  LanguageSelector,
  Logo,
  SearchWidget

} from '@plone/volto/components';
import './style.css';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  /* Search widget:
            <div className="tools-search-wrapper">
              <LanguageSelector />
              {!this.props.token && (
                <div className="tools">
                  <Anontools />
                </div>
              )}
              
            </div>
              <div className="search">
                <SearchWidget />
              </div>
  */
  render() {
    return (
      <Segment basic className="header-wrapper" role="banner">
        <Container id="header-container">
          <div className="header">
            <div className="logo-nav-wrapper ">
              <div className="logo">
                <div className='logo_svg'>
                  <Logo />
                  <NavLink to={'/'}>
                    Das Neurovaskuläre Netzwerk
                    Ostsachsen und Südbrandenburg
                  </NavLink>
                </div>
              </div>

              <Navigation pathname={this.props.pathname} />

              {/*
              <div className="search">
                <SearchWidget />
              </div>
              */ }
            </div>
          </div>

        </Container>
      </Segment>
    );
  }
}

export default connect((state) => ({
  token: state.userSession.token,
}))(Header);
