import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
// import pdfIcon from './PDF_icon.svg';
import defaultIcon from './link-45deg.svg'; // Add a default icon for unknown types

const NeuigkeitenListe = ({ items, isEditMode }) => {
  return (
    <>
      <div className="items">
        {items.map((item) => (
          <div className="listing-item" key={item['@id']}>
            <ConditionalLink to={item.link} condition={!isEditMode}>
              <div className="listing-body">
                <div className='listing-container'>
                  <img
                    src={defaultIcon}
                    alt={'Icon'}
                    className="listing-icon"
                  />
                  <h4>{item.title ? item.title : item.id}</h4>
                </div>
                <p>{item.description}</p>
              </div>
            </ConditionalLink>
          </div>
        ))}
      </div>
    </>
  );
};

NeuigkeitenListe.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default NeuigkeitenListe;
